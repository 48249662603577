import React, {Component} from 'react';
import StageSwitcherItem from "./StageSwitcherItem";

class StageSwitcher extends Component
{
    render() {
        return <div className='modal' onClick={this.props.hideStageSwitcher}>
            <div className="modal-dialog">
                <ul className="stageSwitcherList">
                    {this.props.stages.map((stage, index) => {
                        return <StageSwitcherItem key={index} id={index} title={stage.title} checked={index === this.props.currentStage} onStageClick={this.props.onStageClick} />
                    })}
                </ul>
            </div>
        </div>;
    }
}

export default StageSwitcher;