import React, {Component} from 'react';
import HeatRound from "./HeatRound";

class HeatDate extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };
    }

    render() {
        const { rounds } = this.props;

        return <div className="heat__date-row">
            <div className="heat__date-head">
                <span className="heat__date__date">{this.props.date} <span className="_caret" onClick={() => { this.setState({ open : !this.state.open }) }}>{this.state.open ? "\u25B2" : "\u25BC"}</span> </span>
            </div>
            {this.state.open ?
            <div className="heat__date__rounds">
                <div className="heat__date__rounds-head">
                    <span className="heat__round_time">Estimated time</span>
                    <span className="heat__round_title">Round title</span>
                </div>

                <div className="heat__date__rounds-body">
                    {rounds && Object.keys(rounds).map((key) => <HeatRound key={rounds[key].id} {...rounds[key]} />)}
                </div>
            </div> : null }
            {/* TODO : list of round */}
        </div>
    }
}

export default HeatDate;