import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HeatDate from "./Heat/HeatDate";

class TimeTable extends Component
{
    render() {
        const { heats } = this.props;

        return <div>
            {heats && Object.keys(heats).map((key) => <HeatDate key={key} date={heats[key].date} rounds={heats[key].rounds} />)}
        </div>
    }
}

export default TimeTable;