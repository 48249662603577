import React, { Component } from 'react';

class ResultsTableMedal extends Component
{
    render() {
        return <table className={"results-table results-table--classic"}>
            <thead>
                <tr>
                    <th className={"results-table__column results-table__column--place"}>Place</th>
                    <th colSpan={2} className={"results-table__column results-table__column--country"}>Country</th>
                    <th className={"results-table__column results-table__column--medal"}><img src={process.env.PUBLIC_URL + '/img/medal-gold.svg'} alt="Gold" /></th>
                    <th className={"results-table__column results-table__column--medal"}><img src={process.env.PUBLIC_URL + '/img/medal-silver.svg'} alt="Silver" /></th>
                    <th className={"results-table__column results-table__column--medal"}><img src={process.env.PUBLIC_URL + '/img/medal-bronze.svg'} alt="Bronze" /></th>
                </tr>
            </thead>
            <tbody>
            { this.props.results.map((result, key) => {
                return <tr key={key}>
                    <td className={"results-table__column results-table__column--place"}>{result.pos}</td>
                    <td className={"results-table__column results-table__column--flag"}>{result.iso ? <img src={process.env.PUBLIC_URL + '/img/flags/' + result.iso.toLowerCase() + '.svg'} alt={result.country} /> : <span className="empty-flag" /> }</td>
                    <td className={"results-table__column results-table__column--competitor"}>{result.country}</td>
                    <td className={"results-table__column results-table__column--medal"}>{result.gold}</td>
                    <td className={"results-table__column results-table__column--medal"}>{result.silver}</td>
                    <td className={"results-table__column results-table__column--medal"}>{result.bronze}</td>
                </tr>
            }) }
            </tbody>
        </table>
    }
}

export default ResultsTableMedal;