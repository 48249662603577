import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {default as TimeTableComponent} from '../Components/TimeTable';

import firebase from 'firebase/app';
import 'firebase/database';

import {default as StageSwicherBtn} from "../Components/Buttons/StageSwitcher";
import {default as RealtimeResultsBtn} from "../Components/Buttons/RealtimeResults";
import {default as ResultsBtn} from "../Components/Buttons/Results";
import {default as TimeTableBtn} from "../Components/Buttons/TimeTable";
import {default as BackBtn} from "../Components/Buttons/Back";

class TimeTable extends Component
{
    constructor(props) {
        super(props);

        this.element = null;
        this.data = props.data;

        this.state = {
            heats : {},

            // Buttons
            stageSwitcherBtn : false
        };

        this.firebaseOnValueTimeTableNode = this.firebaseOnValueTimeTableNode.bind(this);
    }

    componentDidMount() {
        // init element and display it
        this.element = document.getElementById('timetable');
        this.element.style.display = 'block';

        // // start listen firebase
        let database = firebase.database();

        if (this.data.timetable !== undefined && this.data.timetable !== null && this.data.timetable !== false) {
            let dataRef = database.ref('timetable/' + this.data.timetable.firebase_id);
            dataRef.on('value', this.firebaseOnValueTimeTableNode);
        }
    }

    componentWillUnmount() {
        if (this.element) {
            this.element.style.display = 'none';
        }
    }

    firebaseOnValueTimeTableNode(snapshot) {
        let value = snapshot.val();

        if (value.data !== undefined && value.data !== null) {
            this.setState({
                heats: value.data,
            });
        }
    }

    render() {
        return <div>
            {ReactDOM.createPortal(<TimeTableComponent heats={this.state.heats} />, document.getElementById('timetable'))}
            <StageSwicherBtn visible={false} />
            <RealtimeResultsBtn visible={false} />
            <ResultsBtn visible={false} />
            <TimeTableBtn visible={false} />
            <BackBtn visible={true} />
        </div>;
    }
}

export default TimeTable;