import {Component} from 'react';

class StageSwitcher extends Component{
    constructor(props) {
        super(props);

        this.stageSwitcherBtn = null;

        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        let stageSwitchButton = document.getElementById('stage_switch');
        if (stageSwitchButton !== null) {
            stageSwitchButton.style.display = 'block';
            stageSwitchButton.onclick = this.onClick;
            this.stageSwitcherBtn = stageSwitchButton;
        }
    }

    onClick(event) {
        event.preventDefault();
        this.props.onClick();

        return false;
    }

    render() {
        return null;
    }
}

export default StageSwitcher;