export default {
    // Realtime results BTN
    REALTIME_RESULTS_BTN_SELECTOR : "realtime_results_btn",
    REALTIME_RESULTS_BTN_NEW_RESULTS_CLASS : "new-results",

    // Results BTN
    RESULTS_BTN_SELECTOR : "results_btn",

    // HeatTable BTN
    TIMETABLE_BTN_SELECTOR : "timetable_btn",
    TIMETABLE_BTN_NEW_ROWS_CLASS : "new-rows",
};