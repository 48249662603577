import React, {Component} from 'react';
import {Route, MemoryRouter} from 'react-router-dom';
import './css/App.css';
import CRPLogo from './img/crp-logo.jpg';
import LiveContent from "./Pages/LiveContent";
import RealtimeResultsPage from "./Pages/RealtimeResults";
import ResultsPage from "./Pages/Results";
import ResultsDetailPage from "./Pages/ResultsDetail";
import cookies from 'react-cookies';
import TimeTable from "./Pages/TimeTable";

class App extends Component {
    constructor(props) {
        super(props);

        this.data = {};
        this.renderScripts = true;
        this.variableElements = {};

        this.state = {
            loaded: false,
            error: false,
            showStageSwitcher: false,
            results : {},
            round_id : null,
            showResults : false,

            // buttons
            stageSwitcherBtn : false,
            resultsBtn : false,
            resultsBackBtn : false
        };

        this.onAppDataReady = this.onAppDataReady.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loaded !== prevState.loaded) {
            let staticVariables = this.data.static_variables || [];

            for (let index in this.data.template.variables) {
                let variable = this.data.template.variables[index];
                let variableHolders = document.querySelectorAll('span[data-variable="'+ variable +'"]');

                for (let vhIndex = 0; vhIndex < variableHolders.length; vhIndex++) {
                    let variableHolder = variableHolders[vhIndex];

                    if (this.variableElements[variable] === undefined) {
                        this.variableElements[variable] = [];
                    }

                    this.variableElements[variable].push(variableHolder.parentNode);
                    variableHolder.parentNode.innerHTML = staticVariables[variable] !== undefined ? staticVariables[variable] : '';
                }
            }
        }
    }

    componentDidMount() {
        let date = new Date();

        if (process.env.NODE_ENV !== 'production') {
            localStorage.removeItem('data')
        }

        let localData = localStorage.getItem('data');
        // let expiresLocalData = localStorage.getItem('data_expires');
        let expired = cookies.load('data-cached') === undefined;

        // if (localData !== null) {
        //     expires = new Date(parseInt(expiresLocalData));
        // }

        if (localData === null || expired) {
            fetch(process.env.REACT_APP_MOBILE_VIEWER_API_URL + '/app/df-slow/fetch-data')
                .then(response => response.json())
                .then(data => {
                    if (false === data) {
                        this.setState({error: true});
                        return;
                    }

                    this.data = data;
                    localStorage.setItem('data', JSON.stringify(data));

                    date.setDate(date.getDate() + 1);
                    date.setHours(4, 0, 0, 0);
                    cookies.save('data-cached', true, { expires : date });

                    this.onAppDataReady();
                })
                .catch(reason => {
                    console.log(reason);
                    this.setState({error: true})
                });
        } else {
            this.data = JSON.parse(localData);
            this.onAppDataReady();
        }
    }

    onAppDataReady() {
        this.setState({
            loaded: true,
            stage: 0
        });

        if ("function" === typeof window.ready) {
            window.ready();
        }
    }

    render() {
        if (this.state.loaded && !this.state.error) {
            let scriptElement;

            if (this.renderScripts) {
                if (typeof this.data.template.scripts === 'object') {
                    let oKeys = Object.keys(this.data.template.scripts);
                    oKeys.forEach(key => {
                        let script = this.data.template.scripts[key];

                        if (key === "inline") {
                            if (Array.isArray(script)) {
                                script.forEach(inlineScript => {
                                    scriptElement = document.createElement('script');
                                    scriptElement.innerHTML = inlineScript;
                                    document.body.appendChild(scriptElement);
                                });
                            } else {
                                scriptElement = document.createElement('script');
                                scriptElement.innerHTML = script;
                                document.body.appendChild(scriptElement);
                            }
                        } else {
                            scriptElement = document.createElement('script');
                            scriptElement.src = script;
                            document.body.appendChild(scriptElement);
                        }
                    });
                }

                this.renderScripts = false;
            }

            let html = this.data.template.html;

            this.data.template.variables.forEach(function (variable) {
                let regex = new RegExp("{" + variable + "}", "g");

                html = html.replace(regex, '<span data-variable="'+ variable +'"></span>');
            });

            return <MemoryRouter>
                <div className="content" dangerouslySetInnerHTML={{__html: html}}/>

                <Route exact path={'/'} component={(props) => <LiveContent {...props} data={this.data} variableElements={this.variableElements} />} />
                {this.data.results !== undefined && this.data.results !== null && this.data.results !== false ? <Route exact path={'/realtime-results'} component={(props) => <RealtimeResultsPage {...props} data={this.data} />} /> : null }
                {this.data.results !== undefined && this.data.results !== null && this.data.results !== false ? <Route exact path={'/results'} component={(props) => <ResultsPage {...props} data={this.data} />} /> : null }
                {this.data.results !== undefined && this.data.results !== null && this.data.results !== false ? <Route exact path={'/results/:round_id'} component={(props) => <ResultsDetailPage {...props} data={this.data} />} /> : null }
                {this.data.timetable !== undefined && this.data.timetable !== null && this.data.timetable !== false ? <Route exact path={'/timetable'} component={(props) => <TimeTable {...props} data={this.data} />} /> : null }

            </MemoryRouter>
        } else {
            if (this.state.error) {
                return <div className="Loader">
                    <img src={CRPLogo} alt="CR Production"/>
                    <span>An error has occurred, there is probably no event going on at this time.</span>
                </div>
            }

            return <div className="Loader">
                <img src={CRPLogo} alt="CR Production"/>
                <span>Loading...</span>
            </div>
        }
    }
}

export default App;
