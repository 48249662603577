import {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { default as Const } from '../../constants';

class TimeTable extends Component {
    constructor(props) {
        super(props);

        this.timetableBtn = null;

        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        let timetableButton = document.getElementById(Const.TIMETABLE_BTN_SELECTOR);

        if (timetableButton !== null) {
            timetableButton.onclick = this.onClick;
            this.timetableBtn = timetableButton;
        }
    }

    onClick(event) {
        event.preventDefault();

        if (this.props.onClick) {
            this.props.onClick();
        }

        this.props.history.push('/timetable');

        return false;
    }

    render() {
        if (this.timetableBtn) {
            this.timetableBtn.style.display = true === this.props.visible ? 'block' : 'none';
        }

        if (this.props.hasNewRows) {
            this.timetableBtn.classList.toggle(Const.TIMETABLE_BTN_NEW_ROWS_CLASS);
        }

        return null;
    }
}
export default withRouter(TimeTable);