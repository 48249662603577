import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ResultsList extends Component
{
    fixNbsp(string) {
        if (!string) { return string; }

        let foundSpecialChars = string.match(/([ ]*)?[^A-Za-z0-9 ]([ ]*)?/);
        let specialCharsCounts = {};

        if (foundSpecialChars) {
            for (let i = 0; i < foundSpecialChars.length; i++) {
                let specialChar = foundSpecialChars[i];

                if (specialChar !== undefined && specialChar !== null) {
                    if (specialCharsCounts[specialChar] === undefined) {
                        specialCharsCounts[specialChar] = 0;
                    }

                    let regex = new RegExp(`[${specialChar}]`, 'g');
                    specialCharsCounts[specialChar] = string.match(regex) ? string.match(regex).length : 0;
                }
            }
        }

        let delimiter = Object.keys(specialCharsCounts).length > 0 ? Object.keys(specialCharsCounts).reduce((a, b) => specialCharsCounts[a] > specialCharsCounts[b] ? a : b) : null;

        if (delimiter !== null) {
            let parts = string.split(delimiter);

            parts = parts.map((part) => part.replace(/ /g, '\u00a0'));
            return parts.join(delimiter);
        }

        return string;
    }

    render() {
        const { results } = this.props;

        return <div>
            <ul className="results__list">
                {results ? Object.keys(results).map((key) => {
                    let resultRow = results[key];
                    if (!resultRow.published_app) { return; }

                    return <li key={key} className="results__list__item"><Link to={'/results/' + key}>{ this.fixNbsp(resultRow.title) }</Link></li>
                }) : <li className="results__list__item results__list__item--no-items">No results found</li>}
            </ul>
        </div>
    }
}

export default ResultsList;