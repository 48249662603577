import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {default as ResultsComponent} from '../Components/Results';

import firebase from 'firebase/app';
import 'firebase/database';

import {default as StageSwicherBtn} from "../Components/Buttons/StageSwitcher";
import {default as ResultsBtn} from "../Components/Buttons/Results";
import {default as RealtimeResultsBtn} from "../Components/Buttons/RealtimeResults";
import {default as TimeTableBtn} from "../Components/Buttons/TimeTable";
import {default as BackBtn} from "../Components/Buttons/Back";


class RealtimeResults extends Component
{
    constructor(props) {
        super(props);

        this.element = null;
        this.data = props.data;

        this.state = {
            results : {},
            round_id : null,

            // Buttons
            stageSwitcherBtn : false
        };

        this.firebaseOnValueResultsNode = this.firebaseOnValueResultsNode.bind(this);
    }

    componentDidMount() {
        // init element and display it
        this.element = document.getElementById('results');
        this.element.style.display = 'block';

        // // start listen firebase
        let database = firebase.database();

        if (this.data.results !== undefined && this.data.results !== null && this.data.results !== false) {
            let dataRef = database.ref('results/' + this.data.results.firebase_id);
            dataRef.on('value', this.firebaseOnValueResultsNode);
        }
    }

    componentWillUnmount() {
        if (this.element) {
            this.element.style.display = 'none';
        }
    }

    firebaseOnValueResultsNode(snapshot) {
        let value = snapshot.val();

        if (value.data[value['published_app']] !== undefined && value.data[value['published_app']] !== null) {
            this.setState({
                results: value.data[value['published_app']],
                round_id: value['published_app']
            });
        }
    }

    render() {
        return <div>
            {ReactDOM.createPortal(<ResultsComponent results={this.state.results} round_id={this.state.round_id} />, document.getElementById('results'))}
            <StageSwicherBtn visible={false} />
            <RealtimeResultsBtn visible={false} />
            <ResultsBtn visible={true} />
            <TimeTableBtn visible={false} />
            <BackBtn visible={true} />
        </div>;
    }
}

export default RealtimeResults;