import React, {Component} from 'react';

class HeatHeat extends Component
{
    constructor(props) {
        super(props);
    }

    render() {
        let returns = [<tr key={"heat_" + this.props.id} className={"heat_heat-row heat_heat-row--" + this.props.bgClass}>
            <td className="heat__heat-id" rowSpan={this.props.competitors.length + 1}>{this.props.id}</td>
        </tr>];

        if (this.props.competitors.length) {
            this.props.competitors.forEach((comp, key) => {
                returns.push(<tr key={"heat_" + this.props.id + '_' + key} className={"heat_heat-competitor-row heat_heat-competitor-row--" + this.props.bgClass}>
                    <td key="stn" className="heat_heat-competitor-stn">{comp.stn}</td>
                    <td key="name" className="heat_heat-competitor-name">{comp.name}</td>
                    <td key="country" className="heat_heat-competitor-country">{comp.country}</td>
                </tr>);
            });
        }

        return returns;
    }
}

export default HeatHeat;