import React, {Component} from 'react';

class StageSwitcherItem extends Component
{
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.props.onStageClick(this.props.id);
    }

    render() {
        return <li className='stage' onClick={this.onClick}>
            { this.props.title }
            <span className={'checkbox' + (this.props.checked ? ' checked' : '')} />
        </li>;
    }
}

export default StageSwitcherItem;