import {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { default as Const } from '../../constants';

class RealtimeResults extends Component {
    constructor(props) {
        super(props);

        this.resultsBtn = null;

        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        let resultsButton = document.getElementById(Const.REALTIME_RESULTS_BTN_SELECTOR);

        if (resultsButton !== null) {
            resultsButton.onclick = this.onClick;
            this.resultsBtn = resultsButton;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.resultsBtn) {
            if (this.props.hasNewResults) {
                this.resultsBtn.classList.add(Const.REALTIME_RESULTS_BTN_NEW_RESULTS_CLASS);
            } else {
                this.resultsBtn.classList.remove(Const.REALTIME_RESULTS_BTN_NEW_RESULTS_CLASS);
            }
        }
    }

    onClick(event) {
        const {onClick, isRealtime, history} = this.props;
        event.preventDefault();

        if (onClick) {
            onClick();
        }

        if (isRealtime) {
            history.push('/realtime-results');
        } else {
            history.push('/results');
        }

        return false;
    }

    render() {
        if (this.resultsBtn) {
            this.resultsBtn.style.display = true === this.props.visible ? 'block' : 'none';
        }

        return null;
    }
}
export default withRouter(RealtimeResults);