import React, { Component } from 'react';
import ResultsTableClassic from "./Results/ResultsTableClassic";
import ResultsTableFinal from "./Results/ResultsTableFinal";
import ResultsTableMedal from "./Results/ResultsTableMedal";

class Results extends Component
{
    getTable() {
        switch(this.props.results.type) {
            case 'classic' : return <ResultsTableClassic results={this.props.results.results} />;
            case 'final' : return <ResultsTableFinal results={this.props.results.results} />;
            case 'medals' : return <ResultsTableMedal results={this.props.results.results} />;
            default : return null;
        }
    }

    render() {
        return <div>
            <div className="header-texts">
                <div className="round-title">{ this.props.results ? this.props.results.title : '' }</div>
            </div>
            {this.getTable()}
        </div>
    }
}

export default Results;