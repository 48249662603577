import {Component} from 'react';
import { withRouter } from 'react-router-dom';

class Back extends Component {
    constructor(props) {
        super(props);

        this.backBtn = null;

        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        let resultsBackButton = document.getElementById('results_nav_back');

        if (resultsBackButton !== null) {
            resultsBackButton.onclick = this.onClick;
            this.backBtn = resultsBackButton;
        }
    }

    onClick(event) {
        event.preventDefault();

        this.props.history.goBack();
        document.documentElement.scrollTop = 0;

        return false;
    }

    render() {
        if (this.backBtn) {
            this.backBtn.style.display = true === this.props.visible ? 'block' : 'none';
        }

        return null;
    }
}
export default withRouter(Back);